
.about{
    width: 70%;
    margin: auto;
}

.big__space__text{
    font-size: 2.8em;
    min-width: 500px;
    max-width: 500px;
    letter-spacing: 3px;
    margin-right: 200px;
    line-height: 1.45em;
}

.text__image__top{
    display: flex;
    flex-direction: row;
    justify-content:center;
}

.image__text__block{
    margin-right: 75px;
}

.image__text__block__image{
    max-width: 100%;
    object-fit: cover;
    
}

.image__text__block__text{
    width: 590px;
    line-height: 1.75em;
    font-size: 2em;
    margin-top: 80px;
}

.experience__block{
    /*margin-left: 16%;*/
    max-width: 656px;
    margin-bottom: 100px;
    margin-top: 200px;
}

.about__h2{
    font-family: "Ginger";
    font-size: 2.8em;
    font-weight: normal;
}

.experience__block__ingress{
    /*letter-spacing: 1px;*/
    font-size: 1.8em;
    line-height: 1.75em;
}

.experience__horizontal__block__content{
    margin-top: 90px;
}

.experience__block__dropdown_heading{
    font-weight: bold;
    margin: 0;
    margin-bottom: 7px;
   
    font-size: 1.8em;
    line-height: 1.75em;
}

.experience__block__dropdown_content_info{
    margin: 0;

    font-style: italic;
    font-size: 1.3em;
    line-height: 1.75em;
}

.experience__block__dropdown_content_my_role{
    font-size: 1.8em;
    line-height: 1.75em;
}

.quality__block{
    margin-left: auto;
    width: 620px;
    margin-top: 200px;
    margin-bottom: 200px;
    font-family: "Manrope";
}

.quality__block p{
    font-size: 1.8em;
    line-height: 1.75em;
    font-family: "ManropeLight";
}

.passion__block{
    width: 620px;
    /*margin-left: 16%;*/
    font-family: "Manrope";
}

.passion__block p{
    font-size: 1.8em;
    line-height: 1.75em;
    font-family: "ManropeLight";
}

.experience__block__elements{
    display: -webkit-inline-box;
    overflow-x: overlay;
    margin: 0 auto;
    width: 100%;
}

.experience__block__element{
    max-width: 650px;
}

.experience__block__dropdown_heading a{
    text-decoration: none;
    text-decoration: none;
  --s: 0.1em;   /* the thickness of the line */
  --c: rgb(255,45,245); /* the color */
  color: #0000;
  padding-bottom: var(--s);
  background: 
    linear-gradient(90deg,var(--c) 50%,#000 0) calc(100% - var(--_p,0%))/200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) var(--s) no-repeat;
  -webkit-background-clip: text,padding-box;
  background-clip: text,padding-box;
  transition: 0.3s;
  max-width: fit-content;
    
}

.experience__block__dropdown_heading a:hover{
    --_p: 100%;
}

@keyframes bounceBig2 {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-5px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-2px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
  }

.experience__block__dropdown_heading__punktum{
    display: inline-block;
    height: 7px;
    width: 7px;
    background-color: rgb(255,45,245);
    border-radius: 50%;
    animation: bounceBig2 2s infinite;
    margin-left: 3px;
  }
  

@media only screen and (max-width: 1200px) {
    .image__text__block__text{
        width: 400px;
    }
}

@media only screen and (max-width: 1000px) {

    .about{
        width: 100%;
        margin: auto;
    }

    .about__h2{
        font-size: 2em;
    }

    .experience__block__ingress{
        font-size: 1.4em;
    }

    .big__space__text{
        max-width: 100%;
        font-size: 2.1em;
        min-width: 0px;
        margin: 0px;
        margin-bottom: 30px;
    }

    .text__image__top{
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
    }

    .image__text__block{
        margin-right: 36px;
    }

    .image__text__block__text{
        margin-top: 40px;
        max-width: 100%;
        line-height: 1.75em;
        font-size: 1.4em;
    }
    

    .image__text__block{
        margin-right: 0px;
    }

    .experience__block{
        margin-left: 0px;
        max-width: 100%;
        margin-top: 75px;
    }

    .experience__block__dropdown_heading{
        font-size: 1.4em;
        /* margin-top: 45px; */
    }

    .experience__block__dropdown_content_info{
        font-size: 1.4em;
    }

    .experience__block__dropdown_content_my_role{
        font-size: 1.4em;
    }

    .quality__block{
        width: 100%;
        margin-right: 0px;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .quality__block p{
        font-size: 1.4em;
    }

    .passion__block{
        width: 100%;
        margin-top: 100px;
        margin-left: 0px;
    }
    
    .passion__block p{
        font-size: 1.4em;
    }

    .experience__block__dropdown_heading__punktum{
        margin-left: 4px;
        height: 5px;
        width: 5px;
      }

}

