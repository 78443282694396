
.work__mosaic{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 1900px;
    margin-top: 150px;
  }

  .work__mosaic__img__wrap {
    position: relative;
    cursor: pointer;
  }

  .work__mosaic__link{
    text-decoration: none;
    color: #000000;
    transition: 0.4s;
  }

  .image{
    object-fit: cover;
  }

  .work__mosaic__link:hover{
    text-decoration: none;
    color: rgb(255,45,245);
  }


  .work__mosaic__img__wrap:nth-child(even) {
    margin-top: 140px;
    margin-left: 90px;
  }

  .work__mosaic__img__img:hover{
    box-shadow: 0px 2px 75px 0px rgba(0, 0, 0, 0.2);
  }

  .work__mosaic__img__img {
    width: 650px;
    height: 900px;
    object-fit: cover;
    transition: 0.3s;
    /* --c: rgb(255,45,245); 
    --b: 0em;   
    --d: 25px;   
  
    --_s: calc(var(--d) + var(--b));
  
    color: var(--c);
    border: solid #0000;
    border-width: var(--b) var(--b) var(--_s) var(--_s);
    background:
      conic-gradient(at left var(--d)  bottom var(--d),
        #0000 90deg, rgb(255 255 255 /0.3) 0 225deg,rgb(255 255 255 /0.6) 0) border-box,
      conic-gradient(at left var(--_s) bottom var(--_s),
        #0000 90deg,var(--c) 0) 0 100%/calc(100% - var(--b)) calc(100% - var(--b))  border-box;
    transform: translate(calc(var(--d)/-1),var(--d));
    clip-path: 
      polygon(
        var(--d) 0%, 
        var(--d) 0%, 
        100% 0%, 
        100% calc(100% - var(--d)),
        100% calc(100% - var(--d)),
        var(--d) calc(100% - var(--d))
      );
    transition: 0.3s; */

  }

  .work__mosaic__img__img:hover{
    transform: translate(0,0);
    clip-path: 
      polygon(
      0% var(--d), 
      var(--d) 0%, 
      100% 0%, 
      100% calc(100% - var(--d)), 
      calc(100% - var(--d)) 100%, 
      0% 100%
    );
  }

  .work__mosaic__title{
    padding: 0;
    margin: 0;
    margin-top: 10px;
    font-family: 'Manrope';
    font-size: 2em;
    font-weight: normal;
  }

  .work__mosaic__subtext{
    padding: 0;
    margin: 0;
    font-family: 'Manrope';
    font-size: 1.4em;
    margin-left: 2px;
  }

  @media only screen and (max-width: 1600px) {
    .work__mosaic__img__img {
      width: 450px;
      height: 700px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .work__mosaic__img__img {
      width: 350px;
      height: 600px;
    }
  }

  @media only screen and (max-width: 1000px) {

    .work__mosaic__work__mosaic{
      display: flex;
      max-width: 40%;
      flex-direction: column;
    }

    .work__mosaic__img__wrap:nth-child(even) {
      margin-top: 40px;
      margin-left: 0px;
    }

    .work__mosaic__img__wrap:nth-child(even) {
      margin-top: 100px;
      margin-left: 50px;
    }
  
    .work__mosaic__img__wrap:nth-child(odd) {
      margin-top: 0px;
    }

    .work__mosaic__img__wrap{
      margin-bottom: 0px;
    }
  
    .work__mosaic__img__wrap {
      width: 40%;
      height: fit-content;
      align-self: flex-start
    }

    .work__mosaic{
      margin-top: 50px;
    }

    .work__mosaic__img__img {
      width: 100%;
      height: 400px;
    }

    .work__mosaic__title{
      font-size: 1.5em;
    }
  
    .work__mosaic__subtext{
      font-size: 1em;
    }
  }


  
  @media only screen and (max-width: 800px) {

    .work__mosaic__work__mosaic{
      display: flex;
      max-width: 100%;
      flex-direction: column;
    }

    .work__mosaic__img__wrap:nth-child(even) {
      margin-top: 40px;
      margin-left: 0px;
    }

    .work__mosaic__img__wrap:nth-child(even) {
      margin-top: 0px;
    }
  
    .work__mosaic__img__wrap:nth-child(odd) {
      margin-top: 0px;
    }

    .work__mosaic__img__wrap{
      margin-bottom: 60px;
    }
  
    .work__mosaic__img__wrap {
      width: 100%;
      height: fit-content;
      align-self: flex-start
    }

    .work__mosaic{
      margin-top: 50px;
    }

    .work__mosaic__img__img {
      width: 100%;
      height: 400px;
    }

    .work__mosaic__title{
      font-size: 1.5em;
    }
  
    .work__mosaic__subtext{
      font-size: 1em;
    }

  }
