
@font-face {
  font-family: Manrope;
  src: url("./fonts/Manrope-Regular.ttf");
}


@font-face {
  font-family: ManropeLight;
  src: url("./fonts/Manrope-Light.ttf");
}

@font-face {
  font-family: ManropeBold;
  src: url("./fonts/Manrope-Bold.ttf");
}

@font-face {
  font-family: ManropeExtraLight;
  src: url("./fonts/Manrope-ExtraLight.ttf");
}

@font-face {
  font-family: Ginger;
  src: url("./fonts/Ginger.otf") format("opentype");
}


body {
  font-family: "ManropeLight";
  background: rgb(255,45,245);
  background: linear-gradient(100deg, rgba(255,45,245,0.2) 0%, rgba(176,206,237,0.2) 50%, rgba(255,148,0,0.1) 100%);
}

.container{
  /*margin: auto;
  width: 60%;*/
  margin: 30px 100px 100px 100px;
  height: auto;
}

@media only screen and (max-width: 850px) {
  .container{
    margin: 15px 15px 15px 15px; 
  }
}


/*Animations*/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes gelatine {

  from {
    opacity: 0;
    transform: scale(1, 1);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }

  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  75% { transform: scale(0.95, 1.05); }
}

@keyframes gelatine2 {
  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  75% { transform: scale(0.95, 1.05); }
}

@keyframes bounceBig {
  0%   { transform: scale(200,200)      translateY(0);  }
  10%  { transform: scale(1.1,.9)   translateY(0); }
  30%  { transform: scale(.9,1.1)   translateY(-20px);}
  50%  { transform: scale(1.05,.95) translateY(0); }
  57%  { transform: scale(1,1)      translateY(-4px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
}

@keyframes bounce-in-right {
  0% {
    opacity: 0;
    transform: translateX(1000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-5px);
  }
  80% { transform: translateX(5px);opacity: 1; }
  100% { transform: translateX(0);opacity: 1; }
}


/*Effects on page transistion*/
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 350ms ease-out, transform 400ms ease;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 350ms ease-out, transform 400ms ease;
}

.link__text__effect{
  text-decoration: none;
  --s: 0.1em;   /* the thickness of the line */
  --c: rgb(255,45,245); /* the color */
  color: #0000;
  padding-bottom: var(--s);
  background: 
    linear-gradient(90deg,var(--c) 50%,#000 0) calc(100% - var(--_p,0%))/200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) var(--s) no-repeat;
  -webkit-background-clip: text,padding-box;
  background-clip: text,padding-box;
  transition: 0.3s;
  max-width: fit-content;
}

.link__text__effect:hover{
  --_p: 100%;
}

/* .mouse-cursor-gradient-tracking {
  position: relative;
  background: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: none;
  color: black;
  outline: none;
  overflow: hidden;
}

.mouse-cursor-gradient-tracking span {
  position: relative;
}

.mouse-cursor-gradient-tracking:before {
  --size: 0;
  content: '';
  position: absolute;
  left: var(--x);
  top: var(--y);
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, pink, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.mouse-cursor-gradient-tracking:hover:before {
  --size: 100px;
} */







