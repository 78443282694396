.footer{
    margin-top: 200px;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
  }
  
  .footer .mail p{
    margin: 0;
    margin-bottom: 5px;
    font-size: 1.1em;
    font-weight: bold;
  }

 
  .footer .mail a{
    font-size: 1.5em;
    font-weight: bold;
  }

  
  .footer .social{
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .footer .social a{
    color: #0000;
    font-weight: bold;
  }


  
  @media only screen and (max-width: 850px) {
  
    .footer{
      /*display:unset;
      flex-direction:unset;
      display: block;*/
      margin-top: 100px;
    }
  
    .footer .mail p{
      margin-bottom: 0px;
    }
  
    .footer .mail a{
      font-size: 0.9em;
      padding-bottom: 5px;
    }
  
    .footer .mail{
      margin-bottom: 8px;
    }
  
    .footer .social a{
      margin-bottom: 2px;
      font-size: 0.6em;
    }
  }