.work__text{
  margin-top: 150px;
}

.work__text a{
  font-size: 5em;
}

.work__text p{
  margin-bottom: 50px;
}

  
  @media only screen and (max-width: 850px) {


    .work__text{
      margin-top: 75px;
    }
    
    .work__text a{
      font-size: 2.5em;
    }
    
    .work__text p{
      margin-bottom: 25px;
    }
  }
  