.work .header h1{
    animation-delay: 0s;
    animation-duration: 0s;
    padding: 0px;
    margin: 0px;
    margin: 60px 0px 0px 0px;
    margin-left: -5px;
    font-size: 1.5em;
    font-weight: 100;
    color: black;
    flex-wrap: wrap;
    hyphens: auto;
  }
  
  .work .header .image{
    margin-left: -108px;
    max-width: 100%;
    height: auto;
    margin-right: 100px;
  }
  
  @media only screen and (max-width: 850px) {
    .work .header .image{
      margin-left: -23px;
      max-width: 100%;
      height: auto;
      margin-right: 15px;
    }
    
  }
  
  .work p{
    max-width: 50%;
    margin-top: 80px;
    font-size: 1.3em;
  }
  
  .work .text{
    max-width: 50%;
    margin-top: 80px;
    font-size: 1.3em;
    line-height: 1.68em;
  }
  
  
  .workInfo h1{
    display: flex;
    flex-direction: row;
    margin-right: 100px;
  }
  
  .workInfo{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin-right: 80px;
    margin-top: 60px;
  }
  
  .workInfo h3{
    font-size: 0.4em;
    font-weight: bold;
    margin: 0px;
  }
  
  .workDetails{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .workDetails .details{
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    min-width: 150px;
    margin-left: 100px;
  }
  
  .workDetails .details .detail > p{
    margin-bottom: 2px;
  }
  
  .workDetails .details .detail > p:last-child{
    font-weight: bold;
    min-width: 130px;
  }
  
  .workDetails .details .detail{
    margin-top: 20px;
  }
  
  
  .workDetails p{
    margin: 0;
    font-size: 0.35em;
  }
  
  .work .images{
    display: flex;
    flex-direction: column;
    margin: 0px;
    margin-top: 60px;
  }
  
  .work .images .image{
    width: 100%;
    height: auto;
    max-height: 1000px;
  }
  
  .work .right{
    display: flex;
    flex-direction: column;
    align-items: self-end;
    text-align: end;
  }
  
  .work .right p{
    max-width: 48%;
    line-height: 1.68em; 
  }
  
  .work .right a{
    text-decoration: none;
    color: #d923ff;
    font-weight: bold;
  }
  
  .work .images .divided{
    display: flex;
    flex-direction: row;
    width: 49.4%;
    height: auto;
    margin-top: 20px;
    /* max-height: 750px; */
  }
  
  .work .images .divided img:first-child{
    margin-right: 20px;
  }

  .work .images .dividedthree{
    display: flex;
    flex-direction: row;
    width: 32.4%;
    height: auto;
    margin-top: 20px;
    
  }

  .work .images .dividedthree img{
    margin-right: 20px;
  }
  
  .work .images .dividedthree img:last-child{
    margin-right: 0px;
  }


  
  
  @media only screen and (max-width: 1100px) {
  
    .work .header{
      display:initial;
    }
  
    .work .header h1{
      font-size: 1em;
      margin: 0px;
      margin-bottom: 0px;
      margin-top: 10px;
      margin-left: -3px;
    }
  
    .workInfo{
      display:unset;
      flex-direction:unset;
      justify-content:space-between;
      margin-right: 0px;
    }
  
    .workDetails{
      display: flex;
      flex-direction: row;
      justify-content: start;
    }
  
    .work p{
      margin-top: 40px;
      max-width: 100%;
    }
  
    .workDetails p{
      font-size: 0.3em;
      margin-top: 0px;
    }
  
    .workDetails .details{
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 150px;
      margin-left: 0px;
      margin-top: 20px;
    }
  
    .workDetails .details .detail{
      margin-top: 10px;
    }
  
    .work .right{
      display: block;
      flex-direction: row;
      text-align: start;
    }
  
    .work .right p{
      max-width: 100%;
    }

    .work .text{
      max-width: 100%;
      margin-top: 40px;
      font-size: 1.3em;
      line-height: 1.68em;
    }

    .work .images .divided{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      margin-top: 0px;
    }
    
    .work .images .divided img{
      margin-right: 0px;
      margin-top: 20px;
    }

    .work .images .divided img:first-child{
      margin-right: 0px;
    }

    .work .images .dividedthree{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      margin-top: 20px;
      
    }
  
    .work .images .dividedthree img{
      margin-right: 0px;
      margin-top: 20px;
    }


    .work .images{
      margin-top: 20px;
    }

    
  }
  