.navbar{
    margin-bottom: 60px;
  }
  
  .navbar ul{
    padding: 0px;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-family: "Ginger";
  }
  
  .navbar ul li{
    list-style: none;
    color: #b2b1b1;
    cursor: pointer;
  }
  
  .navbar ul li a{
    font-size: 1.5em;
  }

  .navbar ul .work{
    margin-right: 30px;
  }

  .navbar .navbar__right{
    display:flex;
  }
  
  @media only screen and (max-width: 850px) {
    .navbar ul li a{
      font-size: 1em;
    }
  }