/*Header*/

  .header {
    font-size: 2.9rem;
    display:flex;
    flex-direction: column;
  }
  
  
  .header .bold{
    opacity: 1;
    animation-name: fadeIn;
    animation-delay: 0s;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    font-family: "Manrope";
    flex-wrap: wrap;
  }
  
  .header h1{
    margin: 0;
    margin-right: 30px;
    font-size: 7rem;
    font-weight: 100;
    opacity: 0;
    color: black;
    animation-name: fadeIn;
    animation-delay: 0.75s;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    flex-wrap: wrap;
    hyphens: auto;
    font-family: "ManropeExtraLight";
  }
  
  .header .last{
    margin-right: 0px;
    flex-wrap: wrap;
  }
  
  .header .punktum{
    display: inline-block;
    height: 14px;
    width: 14px;
    background-color: rgb(255,45,245);
    border-radius: 50%;
    animation: bounceBig 3s;
    animation-iteration-count: 1;
    margin-left: 3px;
    margin-top: 108px;
  }
  
  .header .sameLine{
    display: flex;
    flex-wrap: wrap;
  }
  
  .header p{
    margin-top: 50px;
    font-size: 0.5em;
    max-width: fit-content;
  }
  
  .header .cursive{
    margin-top: 80px;
    font-family: "Ginger";
    opacity: 0;
    animation-name: fadeIn;
    animation-delay: 2s;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    font-size: 0.7em;
  }
  
  .header .right{
    margin-top: 50px;
    flex-direction: row;
    align-self: flex-end;
    min-width: 40%;
    opacity: 0;
    animation-name: fadeIn;
    animation-delay: 2.5s;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    line-height: 1.5;
  }
  
  @media only screen and (max-width: 1200px) {
    .header .right{
      margin-top: 10px;
      min-width: 70%;
    }

    .header .punktum{
      margin-top: 105px;
    }
  }

  @media only screen and (max-width: 1000px) {
    .header h1{
      font-size: 5rem;
      margin-right: 15px;
      flex-wrap: wrap;
    }

    .header .punktum{
      margin-top: 70px;
    }

  }
  
  @media only screen and (max-width: 850px) {

    .header p{
      font-size: 0.4em;
    }

    .header .right{
      flex-direction: column;
      align-self:flex-start;
      min-width: 100%;
    }

    .header .punktum{
      height: 7px;
      width: 7px;
      margin-left: 3px;
      margin-top: 75px;
    }
  }

  @media only screen and (max-width: 720px) {
    .header h1{
      font-size: 4rem;
      margin-right: 15px;
      flex-wrap: wrap;
    }

    .header .punktum{
      margin-top: 60px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .header h1{
      font-size: 3rem;
      margin-right: 15px;
      flex-wrap: wrap;
    }

    .header .punktum{
      margin-top: 45px;
    }

    .header .cursive{
      font-size: 0.5em;
    }
  }